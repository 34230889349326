<template>
  <div
    style="z-index: 1"
    class="home-section-mobile d-flex flex-column justify-start"
  >
    <v-img
      style="z-index: 1; margin-top: 3vh"
      alt="ЕДИНАЯ СРЕДА"
      class="shrink mr-2"
      src="~@/assets/logo-pic.svg"
      transition="scale-transition"
      min-width="190"
    />
    <v-card
      class="rounded-xl mt-12"
      style="z-index: 1; width: 80%"
    >
      <slot name="content"></slot>
      <slot name="actions"></slot>
    </v-card>
    <div class="bg-mobile">
      <v-img
        style="background-position: center; background-size: cover"
        height="100%"
        width="100%"
        alt="ЕДИНАЯ СРЕДА"
        class="shrink mr-2"
        src="~@/assets/bg-3.svg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrapperAuth'
}
</script>
