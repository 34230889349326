<template>
  <WrapperAuth>
    <template #content>
      <v-card-title>{{ $t('login.authorization') }}</v-card-title>
      <v-card-text>
        <!--note: onfocus="this.removeAttribute('readonly')" используется только в таком виде, т.к. не является частью объекта Vue-->
        <v-text-field
          v-model="email"
          color="secondary"
          :label="$t('login.login')"
          prepend-icon="mdi-account"
          readonly
          onfocus="this.removeAttribute('readonly')"
          dense
          outlined
        ></v-text-field>
        <v-text-field
          type="password"
          v-model="password"
          :label="$t('login.pass')"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show ? 'text' : 'password'"
          @click:append="show = !show"
          prepend-icon="mdi-lock"
          dense
          outlined
          @keyup.enter="login"
        ></v-text-field>
      </v-card-text>
    </template>
    <template #actions>
      <v-card-actions class="d-flex justify-space-between">
        <router-link
          class="pr-2"
          :to="{name:'mobileRecoveryPassword'}"
          style="font-size: 12px;"
        >
          {{ $t('login.forgotYourPassword') }}
        </router-link>
        <BaseBtn
          color="primary"
          @click="login"
          small
          class="pl-2"
          :loading="isLoading"
          :title="$t('login.enter')"
        />
      </v-card-actions>
    </template>
  </WrapperAuth>
</template>

<script>
import WrapperAuth from '@/components/views/mobile/auth/WrapperAuth'
import BaseBtn from '@/components/base/UI/BaseBtn'
import encrypt from '@/components/mixins/encrypt'
import { writeSystemFile } from '@/components/utils/mobile/filesystemInteraction'

export default {
  name: 'Login',
  components: { BaseBtn, WrapperAuth },
  mixins: [encrypt],
  data () {
    return {
      show: false,
      isLoading: false,
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      this.isLoading = true
      await this.$store.dispatch('auth/login', { data: { email: this.email, password: this.password } })
      const encryptLogin = this.encrypt(this.email)
      const encryptPassword = this.encrypt(this.password)
      await writeSystemFile('profile/l', encryptLogin)
      await writeSystemFile('profile/p', encryptPassword)
      this.isLoading = false
    }
  }
}
</script>

<style scoped>

</style>
